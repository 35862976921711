import { Route, Routes, useNavigate } from "react-router-dom";
import { lazyImport } from "../utils/lazyImport";
import { useEffect } from "react";
import { themeStore } from "../stores/theme";
import { authStore } from "../stores/authUser";
import { useProgressBar } from "../stores/progressBar";
import storage from "../utils/storage";
import { useLocation } from "react-use";

const { Auth } = lazyImport(
  () => import("../features/authentification"),
  "Auth",
);
const { Protected } = lazyImport(() => import("./Protected"), "Protected");
export const AppRoutes = () => {
  const { user } = authStore();
  const { darkTheme } = themeStore();
  const navigate = useNavigate();
  const { progress, setProgress } = useProgressBar();
  const [token] = storage.getToken();
  const location = useLocation();

  useEffect(() => {
    document.body.className = darkTheme ? "dark" : "light";
  }, [darkTheme]);

  useEffect(() => {
    if (
      !user &&
      location.pathname !== "/successfully-confirmation" &&
      location.pathname !== "/reset-password"
    ) {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, navigate]);

  useEffect(() => {
    if (token && progress === "start") {
      setProgress("finish");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [location.pathname]);

  return (
    <Routes>
      {!user ? (
        <Route path="/*" element={<Auth />} />
      ) : (
        <Route path="/*" element={<Protected />} />
      )}
    </Routes>
  );
};
