import { library } from "@fortawesome/fontawesome-svg-core";

import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleLeft,
  faAngleRight,
  faArrowLeft,
  faArrowRight,
  faBars,
  faBook,
  faBookOpen,
  faCalendar as faCalendarSolid,
  faCaretDown,
  faCaretUp,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle,
  faCircleInfo,
  faCircleMinus,
  faCirclePlus,
  faCircleXmark,
  faClipboard,
  faClock,
  faClose,
  faCommentDots,
  faEuroSign,
  faEye,
  faEyeSlash,
  faFile,
  faFileExcel,
  faFileLines,
  faFilter,
  faFolderOpen,
  faGamepad,
  faGear,
  faHourglassHalf,
  faImage,
  faLocation,
  faLocationDot,
  faMoon,
  faNetworkWired,
  faNotesMedical,
  faPen,
  faPencil,
  faPhone,
  faPhotoFilm,
  faPlus,
  faPlusCircle,
  faPuzzlePiece,
  faQuestion,
  faRightFromBracket,
  faRocket,
  faRotateLeft,
  faSearch,
  faSort,
  faSortDown,
  faSortUp,
  faSun,
  faTimes,
  faTrash,
  faUser,
  faUserCheck,
  faUsers,
  faUserTie,
  faUserXmark,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";

import { faCalendar, faFileWord } from "@fortawesome/free-regular-svg-icons";

library.add(
  faClipboard,
  faEye,
  faEyeSlash,
  faTimes,
  faBars,
  faCaretDown,
  faCaretUp,
  faUser,
  faClock,
  faRightFromBracket,
  faSortUp,
  faSortDown,
  faSort,
  faAngleRight,
  faAngleLeft,
  faAngleDoubleRight,
  faAngleDoubleLeft,
  faSearch,
  faFilter,
  faPlusCircle,
  faFileExcel,
  faCalendar,
  faCircleXmark,
  faUserCheck,
  faUserXmark,
  faCalendarSolid,
  faArrowRight,
  faNetworkWired,
  faArrowLeft,
  faCircleInfo,
  faPen,
  faCheck,
  faTrash,
  faFolderOpen,
  faQuestion,
  faFile,
  faFileLines,
  faCirclePlus,
  faCircleMinus,
  faFileWord,
  faRotateLeft,
  faUsers,
  faBookOpen,
  faClose,
  faChevronRight,
  faMoon,
  faSun,
  faRightFromBracket,
  faGear,
  faUserTie,
  faPencil,
  faLocation,
  faLocationDot,
  faPhone,
  faPlus,
  faPhotoFilm,
  faChevronDown,
  faChevronUp,
  faCircle,
  faCheckCircle,
  faEuroSign,
  faBook,
  faClock,
  faHourglassHalf,
  faNotesMedical,
  faCommentDots,
  faVideo,
  faImage,
  faRocket,
  faGamepad,
  faPuzzlePiece,
  faCircleInfo,
  faCircleInfo,
  faChevronLeft,
  faChevronRight,
);
