import { useEffect, useRef } from "react";
import LoadingBar from "react-top-loading-bar";
import { useProgressBar } from "../../stores/progressBar";
export const ProgressBar = () => {
  const { progress, setProgress } = useProgressBar();

  const ref = useRef<any>(null);

  useEffect(() => {
    if (ref?.current) {
      if (progress === "start") {
        ref.current.continuousStart();
      }
      if (progress === "finish") {
        ref.current.complete();
      }
    }
  }, [progress, ref, setProgress]);

  return (
    <LoadingBar
      color="#ef4444"
      ref={ref}
      transitionTime={200}
      waitingTime={300}
    />
  );
};
