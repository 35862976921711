import create from "zustand";
import { persist, devtools } from "zustand/middleware";

type Props = {
  darkTheme: boolean;
  changeTheme: (value: boolean) => void;
};
export const themeStore = create(
  devtools(
    persist<Props>(
      (set) => ({
        darkTheme: false,
        changeTheme: (value) =>
          set(() => ({
            darkTheme: value,
          })),
      }),
      {
        name: "changetheme",
        getStorage: () => localStorage,
      },
    ),
  ),
);
