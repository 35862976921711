import create from "zustand";
import { persist, devtools } from "zustand/middleware";

export type AuthUserTypes = {
  address: string;
  country: string;
  created_at: string;
  email: string;
  id: string;
  name: string;
  phone: string;
  role: string;
  status: string;
  town: string;
  updated_at: string;
  active_courses_count?: number;
  completed_courses_count?: number;
  expired_courses_count?: number;
};

type AuthOptions = {
  user: AuthUserTypes | null;
  setUser: (user: any) => void;
  clearUser: () => void;
};

export const authStore = create(
  devtools(
    persist<AuthOptions>(
      (set) => ({
        user: {} as AuthUserTypes,
        setUser: (values) =>
          set(() => ({
            user: values,
          })),
        clearUser: () =>
          set(() => ({
            user: null,
          })),
      }),
      {
        name: "matchCourse_",
        getStorage: () => localStorage,
      },
    ),
  ),
);
