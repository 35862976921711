import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { queryClient } from "../lib/react-query";
import { QueryClientProvider } from "react-query";
import { ErrorBoundary } from "react-error-boundary";
import { useProgressBar } from "../stores/progressBar";
import { ProgressBar } from "../components/progressBar/ProgressBar";
import { Toaster, ToastBar, toast } from "react-hot-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type ProviderProps = {
  children: React.ReactNode;
};
const ErrorFallback = () => {
  return (
    <div
      className="text-red-500 w-screen h-screen flex flex-col justify-center items-center"
      role="alert"
    >
      <h2 className="text-lg font-semibold">Ooops, nesto nije u redu :( </h2>
      <button
        className="mt-4"
        onClick={() => window.location.assign(window.location.origin)}
      >
        Refresujte stranicu
      </button>
    </div>
  );
};

const errorHandler = (error: Error) => {
  console.log("from error boundary: ", error);
};
export const AppProvider = ({ children }: ProviderProps) => {
  const { progress } = useProgressBar();

  return (
    <React.Suspense fallback={<div className={"loading-page"}></div>}>
      <ErrorBoundary FallbackComponent={ErrorFallback} onError={errorHandler}>
        <QueryClientProvider client={queryClient}>
          <Router>{children}</Router>
        </QueryClientProvider>
      </ErrorBoundary>
      {progress ? <ProgressBar /> : null}
      <Toaster
        position={"top-center"}
        toastOptions={{
          success: {
            style: {
              background: "#e8f7ed",
            },
          },
          error: {
            style: {
              background: "#fddcdc",
            },
          },
          duration: 4000,
        }}
      >
        {(t) => (
          <ToastBar toast={t}>
            {({ icon, message }) => (
              <>
                {icon}
                {message}
                <FontAwesomeIcon
                  onClick={() => toast.dismiss()}
                  className="cursor-pointer absolute right-[4px] top-[2px] rounded-full text-[12px]"
                  icon={["fas", "close"]}
                />
              </>
            )}
          </ToastBar>
        )}
      </Toaster>
    </React.Suspense>
  );
};
