import create from "zustand";

type LoaderTypes = {
  progress: "start" | "finish" | null;
  setProgress: (value: "start" | "finish" | null) => void;
};
export const useProgressBar = create<LoaderTypes>((set) => ({
  progress: null,
  setProgress: (values) =>
    set(() => ({
      progress: values,
    })),
}));
